export enum Routes {
  Home = '/',
  OurStory = '/story',
  Reviews = '/reviews',
  FAQ = '/faq',
  StarterGuide = '/single',
  StarterGuideMobile = '/single-mobile',
  ContactUs = '/contact',
  ContactUsMobile = '/contact-mobile',
  PrivacyPolicy = '/privacy',
  PrivacyPolicyMobile = '/privacy-mobile',
  Terms = '/terms',
  TermsMobile = '/terms-mobile',
}
