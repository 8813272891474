import React, { FC } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import Icon from 'assets/icons/icons.svg';

interface OverlayProps {
  navLinks: { title: string; url: string }[];
  socialLinks: { svgId: string; url: string }[];
  zIndex?: number;
}

const GlobalStyle = createGlobalStyle`
  html {
    overflow: hidden !important;
  }
`;

const Containter = styled.div<{ zIndex?: number }>`
  overflow: auto;
  position: fixed;
  display: block;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  transition: all 0.2s ease;
  padding: 3rem 1rem;
  z-index: ${({ zIndex }) => (zIndex ? zIndex : 99)};
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(1.5rem);
`;

const DefaultULStyle = styled.ul`
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
`;

const LinksListContainer = styled(DefaultULStyle)`
  display: block;
  padding: 2.25rem 0 0.69rem;
`;

const LinkListItem = styled.li`
  margin: 0;
  padding: 0 0 1.68rem;
`;
const LinkItem = styled.a`
  display: block;
  padding: 0.63rem;
  text-align: center;
  font-size: 1rem;
  font-family: Inter;
`;

const SocialsListContainer = styled(DefaultULStyle)`
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin: 0;
  padding: 0;
  height: 1.5rem;
`;

const SocialSvg = styled.svg`
  width: 1.5rem;
  height: 1.5rem;
`;

const Overlay: FC<OverlayProps> = ({ navLinks, socialLinks, zIndex }) => (
  <>
    <GlobalStyle />
    <Containter zIndex={zIndex}>
      <LinksListContainer>
        {navLinks.map((navLink, index) => (
          <LinkListItem key={index}>
            <LinkItem href={navLink?.url}>{navLink?.title}</LinkItem>
          </LinkListItem>
        ))}
      </LinksListContainer>
      <SocialsListContainer>
        {socialLinks.map((socialLink, index) => (
          <li key={index}>
            <a href={socialLink?.url} target="_blank" rel="noreferrer">
              <SocialSvg>
                <use xlinkHref={`#${socialLink?.svgId}`} />
              </SocialSvg>
            </a>
          </li>
        ))}
      </SocialsListContainer>
      <Icon />
    </Containter>
  </>
);

export default Overlay;
